a {
  text-decoration: none;
}

body {
  background: #060211;
  height: 100vh;
  background-image: url(./Images/Dust.png);
  overflow: hidden;
}

.c-main {
  position: relative;
}
.notFoundPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  height: 100vh;
}

.c-cricle_img {
  text-align: end;
}

.selected-niche {
  border: 2px solid white;
  border-radius: 60px;
  /* You can adjust the border radius as needed */
}

.like {
  width: 100%;
  /* Adjust the width as needed */
  height: auto;
  /* Adjust the height as needed */
  animation: like-gif 2s linear infinite;
}

/* .c-cricle_img {
    animation: like-gif 20s steps(28) infinite;
} */

@keyframes like-gif {
  0% {
    /* background-position: 0%; */
    background-image: url(./Images/flowshit.gif);
  }

  100% {
    background-image: url(./Images/flowshit.gif);
  }
}

.c-cricle_img img {
  width: 100%;
  box-shadow: 0px 0px 290px #0d2dd2;
  border-radius: 100%;
  position: relative;
  right: -110px;
  top: -80px;
}

.c-content_box {
  position: absolute;
  top: 0;
  padding: 4vw 7vw;
  display: block;
  width: 100%;
}

.c-content_box-home {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* //////////////heading p text///////// */
.c-content_cont h2 {
  display: flex;
  align-items: center;
}
.c-content_cont h2 .c-content_span-1 {
  font-size: 12.5vw;
  font-weight: 500;
  text-transform: uppercase;
  font-family: "Bebas Neue";
  letter-spacing: 3px;
  background: linear-gradient(288deg, #30cfd000 60%, #ffffff 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-color: #ffffff;
  -webkit-text-stroke-width: 2px;
}

.c-content_cont h2 .c-content_span-1 {
  font-size: 12.5vw;
}

.c-content_cont h2 .c-content_span-3 {
  font-size: 12.5vw;
  font-weight: 500;
  text-transform: uppercase;
  font-family: "Bebas Neue";
  letter-spacing: 3px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-color: #efefef;
  -webkit-text-stroke-width: 2px;
  margin-left: 15px;
}

.c-content_cont h2 .c-content_span-3 {
  font-size: 12.5vw;
}

.c-content_cont h2 .c-content_span-2 {
  font-size: 3vw;
  font-weight: 400;
  letter-spacing: 3px;
  font-family: "Bebas Neue";
  text-transform: uppercase;
  color: #efefef;
  margin-left: 15px;
}
.c-content_cont p {
  font-size: 1.2vw !important;
  line-height: 1.6vw;
}

.c-content_cont p {
  font-weight: 400;
  font-family: "DM Sans", sans-serif;
  color: #efefef;
  letter-spacing: 4px;
}

/* ///////form////////// */
.c-content_formbox {
  padding: 3vw;
  border-radius: 35.025px;
  border: 0.876px solid #37f3ff;
  background: linear-gradient(
    169deg,
    rgba(58, 129, 191, 0.08) 1.85%,
    rgba(65, 48, 90, 0.08) 98.72%
  );
  backdrop-filter: blur(50px);
  margin-top: 3vw;
  border-left-width: 0px;
  border-right-width: 0px;
}

.c-content_formbox h3 {
  color: #fff;
  font-family: "Bebas Neue";
  font-size: 2.4vw;
  font-style: normal;
  letter-spacing: 1px;
  font-weight: 400;
  line-height: 3vw;
  margin-bottom: 1vw;
}

.c-content_formbox form .c-content_formb_input p {
  color: #e2e2e2;
  font-family: DM Sans;
  font-size: 1.2vw;
  letter-spacing: 2px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0px;
  padding-bottom: 7px;
  padding-top: 10px;
}

.c-content_formbox form .c-content_formb_input input {
  border-radius: 10px;
  border: 1px solid #e4e4e4;
  width: 100%;
  background-color: #faebd700;
  color: #fff;
  padding: 5px 10px;
}

.c-content_formbox form .c-content_formb_input button {
  border-radius: 16px;
  border: 3px solid #37f3ff;
  background: #37f3ff;
  width: 100%;
  text-align: -webkit-left;
  margin-top: 20px;
  padding: 0px 30px;
  color: #102fd4;
  font-family: Bebas Neue;
  font-size: 28.041px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.c-content_formbox form .c-content_formb_input button img {
  width: 80%;
}

/* //////////////////////////////////screen two  css///////////////////// */
.c-screen_2 {
  /* background-image: url(./Images/Animate-23scs.svg); */
  /* background-image: url(./Images/flowshit.gif); */
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.c-niche {
  padding: 3% 6%;
  position: absolute;
  top: 0;
  width: 100%;
}

.c-niche_nextbtn {
  text-align: end;
}

.c-niche_nextbtn button {
  border-radius: 16px;
  border: 3px solid #37f3ff;
  background: #37f3ff;
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 1.4vw;
  font-style: normal;
  font-weight: 500;
  line-height: 25.14px;
  padding: 10px 20px;
  text-decoration: none;
}

.c-niche_nextbtn button i {
  margin-left: 10px;
}

.c-niche_nextbtn-done {
  text-align: end;
}

.c-niche_nextbtn-done button {
  border-radius: 16px;
  border: 3px solid #37f3ff;
  background: #37f3ff;
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 1.4vw;
  font-style: normal;
  font-weight: 500;
  line-height: 25.14px;
  padding: 10px 20px;
  text-decoration: none;
}

.c-niche_nextbtn-done button i {
  margin-left: 10px;
}

.c-niche_video {
  display: flex;
  justify-content: center;
}

.c-niche_vide-set {
  width: 400px;
  height: 230px;
  border-radius: 26px;
  background: transparent;
}

.c-niche_box-title {
  margin-top: 0px;
}

.c-niche_box-title h3 {
  color: #d9d9d9;
  font-family: Bebas Neue;
  font-size: 4.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: 5.5vw;
  letter-spacing: 2.5px;
  margin-bottom: 30px;
}

.c-niche_box-main {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 16.5;
  height: 100%;
  outline: none;
}

.c-niche_box-main p {
  border-radius: 30.002px;
  border: 1px solid #37f3ff;
  background: linear-gradient(
    169deg,
    rgba(58, 129, 191, 0.08) 1.85%,
    rgba(65, 48, 90, 0.08) 98.72%
  );
  backdrop-filter: blur(57.1061897277832px);
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 1.1vw;
  font-style: normal;
  justify-content: center;
  display: flex;
  font-style: normal;
  align-items: center;
  font-weight: 400;
  width: 70% !important;
  /* line-height: 38px; */
  padding: 7px 20px;
  cursor: pointer;
  background-color: #0b184e;
  border-left-width: 0px;
  border-right-width: 0px;
}

.selected-niche {
  border: none;
}

.c-niche_box-item {
  border-radius: 57.106px;
  background: linear-gradient(251deg, #17203f 0%, #000 99.76%);
  width: 16.5vw;
  height: 16.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selected-niche .c-niche_box-item {
  border: 0.876px solid #37f3ff;
}

.c-niche_box-item img {
  width: 50px;
}

.c-niche_box-link {
  margin-top: -23px;
  display: flex;
  justify-content: center;
}

/* /////////////////////////////////screen 3///////////////////////// */
/* /////////////////////////////////screen 3///////////////////////// */
.c-niche_boxs3_row {
  justify-content: center;
  display: flex;
}

.c-niche_boxs3-main {
  text-align: center;
  margin: 0px 34px;
}

.c-niche_boxs3-main button {
  border-radius: 40.002px;
  border: 1px solid #37f3ff;
  background: linear-gradient(
    169deg,
    rgba(58, 129, 191, 0.08) 1.85%,
    rgba(65, 48, 90, 0.08) 98.72%
  );
  backdrop-filter: blur(57.1061897277832px);
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  justify-content: center;
  display: flex;
  align-items: center;
  font-weight: 400;
  width: 70% !important;
  padding: 7px 20px;
  background-color: #0b184e;
  border-left-width: 0px;
  border-right-width: 0px;
}

.clicked-button {
  border: 2px solid white;
  border-radius: 10px;
  /* You can adjust the border radius value as needed */
}

.c-niche_boxs3-item {
  border-radius: 57.106px;
  background: linear-gradient(251deg, #17203f 0%, #000 99.76%);
  width: 170px;
  height: 145px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-niche_boxs3-item img {
  width: 50px;
}

.clicked-button .c-niche_boxs3-item {
  border: 0.876px solid #37f3ff;
}

.clicked-button {
  border: 0.876px solid #37f3ff;
}

.c-niche_boxs3-link {
  margin-top: -23px;
  display: flex;
  justify-content: center;
}

/* /////////////////////////////screen 4/////////////////////////////////// */
.c-niche_box-list {
  display: flex;
  align-items: center;
  padding-top: 20px;
}

.c-icon-a {
  margin: 0px 10px;
}

.c-niche_box-list .c-niche_box-list-hh {
  color: #fff;
  font-family: Bebas Neue;
  font-size: 80.077px;
  font-style: normal;
  font-weight: 400;
  line-height: 50.123px;
  /* margin-bottom: 20px; */
  margin-right: 20px;
  margin-left: 20px;
}

@media (min-width: 1500px) and (max-width: 2440px) {
  .c-niche_box-list .c-niche_box-list-hh {
    color: #fff;
    font-family: Bebas Neue;
    font-size: 80.077px;
    font-style: normal;
    font-weight: 400;
    line-height: 50.123px;
    margin-top: 50px;
  }

  .c-niche_box-list p {
    color: #f1f1f1;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 10px;
    line-height: 25px;
  }
}

.c-niche_box-list p {
  color: #f1f1f1;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 10px;
  line-height: 25px;
}

/* //////////////////////////////////////screen 5/////////////////////// */
.c-niche_box_sc5 {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.c-niche_box_sc5 button {
  text-align: center;
  width: 896px;
  padding: 12px 24px;
  border-radius: 16px;
  border: 2px solid #8c8c8c;
  color: #bababa;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  /* 150% */
  letter-spacing: -1px;
}

/* /////////////////////////////////////////////screen 6//////////////////// */
#store {
  text-decoration: none;
  text-align: center;
  width: 100%;
  padding: 12px 24px;
  border-radius: 16px;
  border: 2px solid #8c8c8c;
  color: #bababa;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: -1px;
  background-color: #faebd700;
  margin-top: 20px;
}

input#store:focus {
  outline: none;
  color: #bababa;
  box-shadow: none;
  border: 2px solid #8c8c8c;
  background: #f3f3f300;
}

#accessToken {
  text-decoration: none;
  text-align: center;
  width: 100%;
  padding: 12px 24px;
  border-radius: 16px;
  border: 2px solid #8c8c8c;
  color: #bababa;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: -1px;
  background-color: #faebd700;
  margin-top: 20px;
}

input#accessToken:focus {
  outline: none;
  color: #bababa;
  box-shadow: none;
  border: 2px solid #8c8c8c;
  background: #f3f3f300;
}

.c-cricle_imgs6 {
  text-align: center;
}

.c-cricle_imgs6 img {
  width: 80%;
  border-radius: 100%;
  box-shadow: 10px 10px 310px #1e42ff;
  position: relative;
  top: -90px;
}

.c-cricle_hss img {
  position: relative;
  top: -60px;
}

.c-content_boxh3 h3 {
  color: #d9d9d9;
  font-family: Bebas Neue;
  font-size: 120.51px;
  font-style: normal;
  font-weight: 400;
  line-height: 100.575px;
  text-align: center;
}

.c-content_boxscree-6 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

.c-content_boxscree-6_vide {
  width: 560px;
  height: 250px;
  border-radius: 26px;
  background: #d9d9d9;
}

.c-content_boxscree-btn {
  text-align: center;
  margin-top: 0px;
  display: flex;
  justify-content: center;
}

.c-content_boxscree-btn-done-btn {
  text-align: center;
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.c-content_boxscree-btn-done-btn button {
  padding: 2px 40px;
  border-radius: 16px;
  border: 3px solid #37f3ff;
  background: #37f3ff;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-family: Bebas Neue;
  font-size: 32.041px;
  font-style: normal;
  font-weight: 400;
  line-height: 70.111px;
  text-decoration: none;
}

.c-content_boxscree-btn button {
  padding: 2px 40px;
  border-radius: 16px;
  border: 3px solid #37f3ff;
  background: #37f3ff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #102fd4;
  font-family: Bebas Neue;
  font-size: 32.041px;
  font-style: normal;
  font-weight: 400;
  line-height: 50.111px;
  text-decoration: none;
}

/* /////////////////////////////screen 7////////////////// */
.c-content7_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
}

.c-content_boxh7 h3 {
  font-size: 160px;
  font-weight: 500;
  text-transform: uppercase;
  font-family: "Bebas Neue";
  letter-spacing: 1px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-color: #efefef;
  -webkit-text-stroke-width: 2px;
  margin-left: 15px;
  text-align: center;
}

.c-niche_nextbtn {
  text-align: end;
}

.c-niche_nextbtn a {
  border-radius: 16px;
  border: 3px solid #37f3ff;
  background: #37f3ff;
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 30.64px;
  font-style: normal;
  font-weight: 500;
  line-height: 41.14px;
  padding: 10px 20px;
  text-decoration: none;
}

.c-niche_nextbtn a i {
  margin-left: 20px;
}

.c_screen_8box {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* /////////////////////////// */
input:focus-visible {
  outline: 0px;
}

.c-niche_box-title .row {
  justify-content: space-between;
  --bs-gutter-x: 0px;
  --bs-gutter-y: 0;
}

.c-4c-niche_box-title .row {
  margin-top: 0% !important;
}

.c-create_store {
  margin-top: 40px;
  text-align: center !important;
}

.wistia-embed {
  width: 29.5vw !important;
  height: 16.5vw !important;
}

@media (min-width: 2440px) {
  /* //////////////s1///////////////// */
  .c-content_cont h2 {
    line-height: 170px;
  }

  .c-content_cont h2 .c-content_span-1 {
    font-size: 12.5vw;
    letter-spacing: 5px;
  }

  .c-content_cont h2 .c-content_span-3 {
    font-size: 12.5vw;
    letter-spacing: 5px;
    margin-left: 20px;
  }

  .c-content_cont h2 .c-content_span-2 {
    font-size: 3vw;
    letter-spacing: 5px;
    margin-left: 20px;
  }

  .c-content_formbox {
    padding: 3vw;
  }
  .c-content_formbox h3 {
    font-size: 2.4vw;
  }

  .c-content_formbox form .c-content_formb_input button {
    font-size: 33px;
  }
}

@media (max-width: 2439px) and (min-width: 1920px) {
  /* //////////////s1///////////////// */
  .c-content_cont h2 {
    line-height: 170px;
  }

  .c-content_cont h2 .c-content_span-1 {
    font-size: 12.5vw;
    letter-spacing: 5px;
  }

  .c-content_cont h2 .c-content_span-3 {
    font-size: 12.5vw;
    letter-spacing: 5px;
    margin-left: 20px;
  }

  .c-content_cont h2 .c-content_span-2 {
    font-size: 3vw;
    letter-spacing: 5px;
    margin-left: 20px;
  }

  .c-content_formbox {
    padding: 20px 55px 20px 55px;
  }

  .c-content_formbox h3 {
    font-size: 2.4vw;
  }

  .c-content_formbox form .c-content_formb_input button {
    font-size: 33px;
  }

  /* /////////////////////////screen 2////////////////////// */
  .c-niche {
    padding: 4% 8%;
  }

  .c-niche_nextbtn button {
    border-radius: 22px;
    font-size: 22.64px;
    line-height: 22.14px;
    padding: 15px 30px;
  }

  .c-niche_vide-set {
    width: 480px;
    height: 270px;
  }

  .c-niche_box-title h3 {
    font-size: 70px;
    line-height: 60px;
    letter-spacing: 2.5px;
    margin: 1.5% 0%;
  }

  /* .c-niche_box-main {
        width: 210px;
    } */
  /* .c-niche_box-item {
        width: 210px;
        height: 180px;
        border-radius: 50px;
    } */
  .c-niche_box-item img {
    width: 70px;
  }

  /* .c-niche_box-main p {
        border-radius: 60.002px;
        font-size: 18px;
        letter-spacing: 1.5px;
    } */
  /* /////////////////////////3 screen///////////// */
  .c-niche_boxs3-item {
    border-radius: 60.106px;
    width: 200px;
    height: 180px;
  }

  .c-niche_boxs3-item img {
    width: 70px;
  }

  .c-niche_boxs3-main button {
    border-radius: 40.002px;
    font-size: 20px;
    padding: 7px 20px;
  }

  /* ////////////////////////////screen 4/////////////// */
  .c-niche_box-title .row {
    margin: 3% 0% 0% 0% !important;
  }

  .c-niche_box-list .c-niche_box-list-hh {
    font-size: 90.077px;
    line-height: 0px;
  }

  .c-niche_box-list p {
    font-size: 22px;
    line-height: 25px;
    margin-bottom: -1w0px;
  }

  /* ///////////////////screen 5///////////// */

  .c-niche_box_sc5 {
    margin-top: 4%;
  }

  #store {
    width: 70%;
    padding: 10px 44px;
    font-size: 20px;
  }

  /* ////////////////////////////////6 screen /////////// */
  #accessToken {
    width: 70%;
    padding: 10px 44px;
    font-size: 20px;
  }
}

@media (max-width: 1919px) and (min-width: 1500px) {
  /* //////////////s1///////////////// */
  .c-content_cont h2 {
    line-height: 170px;
  }

  .c-content_cont h2 .c-content_span-1 {
    font-size: 12.5vw;
    letter-spacing: 5px;
  }

  .c-content_cont h2 .c-content_span-3 {
    font-size: 12.5vw;
    letter-spacing: 5px;
    margin-left: 20px;
  }

  .c-content_cont h2 .c-content_span-2 {
    font-size: 3vw;
    letter-spacing: 5px;
    margin-left: 20px;
  }

  .c-content_formbox {
    padding: 3vw;
  }

  .c-content_formbox h3 {
    font-size: 2.4vw;
  }

  .c-content_formbox form .c-content_formb_input button {
    font-size: 33px;
  }

  /* /////////////////////////screen 2////////////////////// */
  .c-niche {
    padding: 4% 8%;
  }

  .c-niche_nextbtn button {
    border-radius: 22px;
    font-size: 22.64px;
    line-height: 22.14px;
    padding: 15px 30px;
  }

  .c-niche_vide-set {
    width: 480px;
    height: 270px;
  }

  .c-niche_box-title h3 {
    font-size: 70px;
    line-height: 60px;
    letter-spacing: 2.5px;
    margin: 1.5% 0%;
  }

  /* .c-niche_box-main {
        width: 210px;
    } */
  /* .c-niche_box-item {
        width: 210px;
        height: 180px;
        border-radius: 50px;
    }
    .c-niche_box-item img {
        width: 70px;
    }
    .c-niche_box-main p {
        border-radius: 60.002px;
        font-size: 18px;
        letter-spacing: 1.5px;
    } */
  /* /////////////////////////3 screen///////////// */
  .c-niche_boxs3-item {
    border-radius: 60.106px;
    width: 200px;
    height: 180px;
  }

  .c-niche_boxs3-item img {
    width: 70px;
  }

  .c-niche_boxs3-main button {
    border-radius: 40.002px;
    font-size: 20px;
    padding: 7px 20px;
  }

  /* ////////////////////////////screen 4/////////////// */
  .c-niche_box-title .row {
    margin: 3% 0% 0% 0% !important;
  }

  .c-niche_box-list .c-niche_box-list-hh {
    font-size: 90.077px;
    line-height: 0px;
  }

  .c-niche_box-list p {
    font-size: 24px;
    line-height: 25px;
    margin-bottom: -10px;
  }

  /* ///////////////////screen 5///////////// */
  .c-niche_box_sc5 {
    margin-top: 4%;
  }

  #store {
    width: 70%;
    padding: 10px 44px;
    font-size: 20px;
  }

  /* ////////////////////////////////6 screen /////////// */
  #accessToken {
    width: 70%;
    padding: 10px 44px;
    font-size: 20px;
  }
}

.nobreak {
  margin-bottom: -30px !important;
}

.c-cricle_img {
  height: 100vh;
}

.second-scren.row.m-0 .col-lg {
  width: auto;
}
@media (min-width: 1720px) {
  .second-scren.row.m-0 .col-lg,
  .c-niche_box-main,
  .c-niche_box-item {
    width: 13.5vw;
  }
  .c-niche_box-item {
    height: 12.5vw;
  }
  .c-content_cont h2 .c-content_span-1,
  .c-content_cont h2 .c-content_span-3 {
    font-size: 11.5vw !important;
    line-height: 1;
  }
  .c-content_cont p {
    font-size: 1.2vw !important;
    line-height: 1.8vw;
    letter-spacing: 0.3vw;
  }
  .c-content_formbox {
    padding: 2.5vw !important;
  }
  .c-content_formbox h3 {
    font-size: 2.4vw !important;
    margin-bottom: 1vw;
  }
  .c-content_formbox form .c-content_formb_input p {
    font-size: 1vw;
    line-height: 1.2vw;
  }
  .c-content_formbox form .c-content_formb_input input {
    height: 3.2vw;
  }
  .c-content_formbox form .c-content_formb_input button {
    font-size: 1.5vw;
    height: 3.5vw;
    margin-top: 1.2vw;
  }
  .c-content_box {
    padding: 5vw 7vw;
  }
  .c-niche_boxs3-item {
    width: 13.5vw;
    height: 12.5vw;
  }
  .c-niche_box-title h3 {
    margin: 2.5% 0%;
  }
  .c-niche_vide-set {
    width: 29.5vw !important;
    height: 16.5vw !important;
  }
  .c-niche_box-title h3 {
    font-size: 4.5vw !important;
    line-height: 121px !important;
  }
  .screen-four .col-md-3 {
    width: auto;
  }
  .c-niche_box-list p {
    font-size: 1.2vw;
    line-height: 1.4vw;
    padding-left: 10px;
  }
  .c-niche_nextbtn button {
    border-radius: 16px;
    font-size: 32px !important;
  }
  .c-content_boxscree-6_vide {
    width: 29.5vw;
    height: 16.5vw;
    border-radius: 26px;
    background: transparent !important;
  }
  .c-content_boxscree-btn button {
    font-size: 2.2vw;
    height: 4vw;
  }
  .c-content_boxscree-btn-done-btn button {
    font-size: 2.2vw;
    height: 4vw;
  }
  .c-content_box.c-content7_box .c-content_boxh3 h3 {
    font-size: 10.2vw;
    line-height: 11.5vw;
  }
  .c-content_boxh7 h3 {
    font-size: 11.5vw;
  }
}
.c-niche_box_sc5 {
  display: flex;
  flex-flow: column;
  align-items: center;
}
@media (min-width: 1181px) {
  .second-scren.row.m-0 .col-lg {
    width: auto;
    margin: 0;
    padding: 0;
    flex: 0;
  }
  .second-scren.row.m-0 {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
}
@media (min-width: 820px) and (min-width: 1180px) {
  .c-content_cont h2 .c-content_span-1,
  .c-content_cont h2 .c-content_span-3 {
    font-size: 12.5vw;
    line-height: 1;
  }

  .c-cricle_img {
    height: 100vh;
  }

  .second-scren.row.m-0 {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .second-scren.row.m-0 .col-lg {
    width: auto;
    margin: 0;
    padding: 0;
    flex: 0;
  }
}

@media (max-width: 819px) {
  .c-content_cont h2 .c-content_span-1,
  .c-content_cont h2 .c-content_span-3 {
    font-size: 12.5vw;
    line-height: 1;
  }

  .c-cricle_img {
    height: 100vh;
  }

  .second-scren.row.m-0 {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
  }

  .second-scren.row.m-0 .col-lg {
    width: auto;
  }
}

@media (max-width: 676px) {
  .c-content_cont h2 .c-content_span-1,
  .c-content_cont h2 .c-content_span-3 {
    font-size: 12.5vw;
    line-height: 1;
  }

  .c-content_cont h2 {
    line-height: 1;
  }

  .c-content_formbox {
    padding: 6vw;
  }

  .c-content_formbox form .c-content_formb_input button {
    font-size: 20.041px;
  }

  .c-content_formbox form .c-content_formb_input button img {
    width: 50%;
  }

  .c-content_formbox h3 {
    font-size: 5.2vw;
    line-height: 45px;
  }

  .c-niche_box-title h3 {
    font-size: 7vw;
    line-height: 42px;
    margin: 0;
  }

  .second-scren.row.m-0 {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .second-scren.row.m-0 .col-lg {
    padding: 0;
    width: auto;
  }
}

@media (max-width: 480px) {
  .c-niche_box-item {
    border-radius: 37.106px;
    width: 40.5vw;
    height: 31vw;
  }
  .c-niche_nextbtn button {
    font-size: 5.4vw;
  }

  .c-niche_box-main {
    width: 40.5vw;
  }

  .c-niche_box-main p {
    font-size: 10px;
  }

  .c-niche_box-list .c-niche_box-list-hh {
    font-size: 42.077px;
    line-height: 45px;
  }

  .c-content_boxh3 h3 {
    font-size: 48.51px;
  }
  .c-content_cont p {
    font-size: 2.4vw !important;
    line-height: 3.5vw;
    letter-spacing: 0.4vw;
  }
  input#store {
    width: 100%;
  }
  input#accessToken {
    width: 100%;
  }
  .c-content_boxscree-6_vide {
    width: 80vw;
    height: 45vw;
    border-radius: 26px;
  }
  .c-content_boxh7 h3 {
    font-size: 20vw;
  }
  .c-content_formbox form .c-content_formb_input p {
    font-size: 2.2vw;
  }
  .c-niche_boxs3-main {
    margin: 0 10px;
  }
}

@media (max-width: 400px) {
  .c-content_cont h2 .c-content_span-1,
  .c-content_cont h2 .c-content_span-3 {
    font-size: 12.5vw;
    line-height: 1;
  }

  .c-niche_box-main {
    width: 40.5vw;
  }

  .c-niche_box-item {
    border-radius: 27.106px;
    width: 40.5vw;
    height: 100px;
  }

  .c-niche_vide-set {
    width: 100%;
    height: 180px;
    border-radius: 26px;
    background: transparent;
  }
}

@media (max-width: 768px) {
  body {
    overflow: auto !important;
  }

  .c-niche_nextbtn {
    margin-bottom: 50px;
  }

  .wistia-embed {
    width: 300px !important;
    margin-bottom: 120px;
  }
}

.c-content_boxscree-btn-done-btn {
  text-align: center;
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.c-content_boxscree-btn-done-btn button {
  padding: 2px 40px;
  border-radius: 16px;
  border: 3px solid #37f3ff;
  background: #37f3ff;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-family: Bebas Neue;
  font-size: 32.041px;
  font-style: normal;
  font-weight: 400;
  line-height: 70.111px;
  text-decoration: none;
}

.c-content_boxscree-btn-next-done-btn {
  text-align: end;
  margin-top: 40px;
  display: flex;
  justify-content: end;
}

.c-content_boxscree-btn-next-done-btn button {
  padding: 2px 40px;
  border-radius: 16px;
  border: 3px solid #37f3ff;
  background: #37f3ff;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-family: Bebas Neue;
  font-size: 32.041px;
  font-style: normal;
  font-weight: 400;
  line-height: 70.111px;
  text-decoration: none;
}
